import React, { useState } from 'react';
import './RegisterScreen.css';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';

const RegisterScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async () => {
    if (!email || !password) {
      alert('Please enter both email and password.');
      return;
    }

    setLoading(true);
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      alert('Registration successful. Please log in to continue.');
      
      // Redirect to the login page after successful registration
      navigate('/login', { state: { from: 'register' } });
    } catch (error) {
      alert(`Registration Failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="register-container">
      <img src="/blitz_logo.png" alt="BLITZ Logo" className="logo" />
      <h1>Register</h1>
      <input 
        type="email" 
        placeholder="Email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        className="input-field"
      />
      <input 
        type="password" 
        placeholder="Password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        className="input-field"
      />
      <button 
        onClick={handleRegister} 
        className="register-button"
        disabled={loading}
      >
        {loading ? 'Registering...' : 'Register'}
      </button>
      <p className="footer-text">
        Already have an account?{' '}
        <span 
          className="login-link" 
          onClick={() => navigate('/login')}
        >
          Login
        </span>
      </p>
    </div>
  );
};

export default RegisterScreen;
