// InstaBlitzDashboard.js

import React, { useEffect, useState, useMemo } from 'react';
import { collection, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { db, auth } from '../firebase'; // Ensure Firebase is initialized correctly
import { useNavigate } from 'react-router-dom'; // For navigation
import './InstaBlitzDashboard.css'; // CSS file for styling

const InstaBlitzDashboard = () => {
  const [instablitzDrivers, setInstablitzDrivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  // Fetch InstaBlitz driver applications from Firestore
  useEffect(() => {
    const fetchInstaBlitzDrivers = () => {
      try {
        const unsubscribe = onSnapshot(
          collection(db, 'fastTrackDrivers'),
          (snapshot) => {
            const drivers = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setInstablitzDrivers(drivers);
            setLoading(false);
          },
          (err) => {
            console.error('Error fetching InstaBlitz drivers:', err);
            setError('Error fetching InstaBlitz drivers.');
            setLoading(false);
          }
        );
        return () => unsubscribe();
      } catch (err) {
        console.error('Error setting up snapshot listener:', err);
        setError('Error setting up data listener.');
        setLoading(false);
      }
    };

    fetchInstaBlitzDrivers();
  }, []);

  // Filter drivers based on search term using useMemo for optimization
  const filteredDrivers = useMemo(() => {
    return instablitzDrivers.filter(driver => 
      (driver.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      driver.email?.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [instablitzDrivers, searchTerm]);

  // Delete driver from Firestore
  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this driver?')) return;

    try {
      await deleteDoc(doc(db, 'fastTrackDrivers', id));
      // Firestore's onSnapshot will handle updating the state
    } catch (error) {
      console.error('Error deleting driver:', error);
      setError('Error deleting driver.');
    }
  };

  // Handle Logout
  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error logging out:', error);
        setError('Error logging out.');
      });
  };

  // Navigate back to the main admin dashboard
  const goToAdminDashboard = () => {
    navigate('/admin');
  };

  return (
    <div className="instablitz-dashboard">
      <header className="dashboard-header">
        <h2>InstaBlitz Drivers</h2>
        <div className="header-buttons">
          <button onClick={goToAdminDashboard} className="back-to-admin-btn">Back to Admin Dashboard</button>
          <button onClick={handleLogout} className="logout-btn">Logout</button>
        </div>
      </header>

      {/* Search bar */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by name or email..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>

      {/* Show loading message while data is loading */}
      {loading && <div className="loading">Loading InstaBlitz driver applications...</div>}

      {/* Show error message if any error occurs */}
      {error && <div className="error">{error}</div>}

      {/* Drivers Table */}
      {!loading && !error && (
        <>
          {filteredDrivers.length === 0 ? (
            <div className="no-drivers">No drivers found for InstaBlitz.</div>
          ) : (
            <div className="table-container">
              <table className="drivers-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>License Verified</th>
                    <th>License Image</th> {/* New column for License Image */}
                    <th>Onboarded</th>
                    <th>Onboarding Time</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDrivers.map(driver => (
                    <tr key={driver.id}>
                      <td>{driver.name || 'No Name'}</td>
                      <td>{driver.email || 'No Email'}</td>
                      <td>{driver.phone || 'No Phone'}</td>
                      <td>{driver.isLicenseVerified ? 'Yes' : 'No'}</td>
                      <td>
                        {driver.licenseImageUrl ? (
                          <img 
                            src={driver.licenseImageUrl} 
                            alt={`${driver.name}'s License`} 
                            className="license-img" 
                            loading="lazy" // Lazy loading for performance
                          />
                        ) : (
                          'No Image'
                        )}
                      </td>
                      <td>{driver.isOnboarded ? 'Yes' : 'No'}</td>
                      <td>{driver.onboardTimestamp?.toDate().toLocaleString() || 'N/A'}</td>
                      <td>
                        <button onClick={() => handleDelete(driver.id)} className="delete-btn">Delete</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InstaBlitzDashboard;
