// src/components/DriverNavigationPage.js

import React, { useEffect, useState, useRef } from 'react';
import './DriverNavigationPage.css';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  useJsApiLoader,
} from '@react-google-maps/api';
import axios from 'axios';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path as needed

// Google Maps API Key from environment variables
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// Define map container style
const containerStyle = {
  width: '100%',
  height: '100vh',
};

const DriverNavigationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [rideInfo, setRideInfo] = useState(null);
  const [directions, setDirections] = useState(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [driverLocation, setDriverLocation] = useState(null);
  const [eta, setEta] = useState('');
  const [distance, setDistance] = useState('');
  const [fare, setFare] = useState('');
  const [arrivalTime, setArrivalTime] = useState('');
  const [mapInstance, setMapInstance] = useState(null);
  const watchIdRef = useRef(null); // Use useRef instead of state
  const [loading, setLoading] = useState(true); // To track loading state
  const [showDetails, setShowDetails] = useState(false); // For expanding bottom panel
  const [driverCut, setDriverCut] = useState(''); // Driver's share of the fare
  const [isMapInteracting, setIsMapInteracting] = useState(false); // Track if user is interacting with the map
  const interactionTimeoutRef = useRef(null); // To clear the timeout

  // Load Google Maps API
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  // Parse URL parameters to get rideID
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const rideID = params.get('rideID');

    if (!rideID) {
      console.error('Missing rideID in URL parameters.');
      alert('Invalid ride information. Redirecting to home.');
      navigate('/');
      return;
    }

    // Fetch ride data from Firestore
    const fetchRideData = async () => {
      try {
        const rideDocRef = doc(db, 'fastTrackRides', rideID); // Use correct collection
        const rideDoc = await getDoc(rideDocRef);

        if (rideDoc.exists()) {
          const data = rideDoc.data();

          // Ensure necessary fields are present
          if (
            data.pickupLocation &&
            data.dropoffLocation &&
            typeof data.distance === 'number' &&
            typeof data.duration === 'number'
          ) {
            setRideInfo({
              rideID: rideID,
              pickup: {
                lat: data.pickupLocation.latitude,
                lng: data.pickupLocation.longitude,
              },
              dropoff: {
                lat: data.dropoffLocation.latitude,
                lng: data.dropoffLocation.longitude,
              },
              distance: data.distance,
              duration: data.duration,
              fare: data.fare || 0,
              rideStatus: data.rideStatus || 'Pending',
              riderID: data.riderID || null,
              // Add other fields as needed
            });

            // Calculate arrival time
            const currentTime = new Date();
            currentTime.setMinutes(
              currentTime.getMinutes() + Math.round(data.duration)
            );
            setArrivalTime(
              currentTime.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })
            );

            // Set fare
            const totalFare = data.fare ? data.fare : 0;
            setFare(totalFare.toFixed(2));

            // Calculate driver's cut (e.g., 75% of fare)
            const driverShare = totalFare * 0.75; // Adjust percentage as needed
            setDriverCut(driverShare.toFixed(2));
          } else {
            console.error('Incomplete ride data.');
            alert('Invalid ride information. Redirecting to home.');
            navigate('/');
          }
        } else {
          console.error('Ride not found.');
          alert('Invalid ride information. Redirecting to home.');
          navigate('/');
        }
      } catch (err) {
        console.error('Error fetching ride data:', err);
        alert('An error occurred. Redirecting to home.');
        navigate('/');
      } finally {
        setLoading(false);
      }
    };

    fetchRideData();
  }, [location.search, navigate]);

  // Fetch pickup and dropoff addresses using reverse geocoding
  useEffect(() => {
    const fetchAddresses = async () => {
      if (rideInfo) {
        try {
          const [pickupResponse, dropoffResponse] = await Promise.all([
            axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${rideInfo.pickup.lat},${rideInfo.pickup.lng}&key=${GOOGLE_MAPS_API_KEY}`
            ),
            axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${rideInfo.dropoff.lat},${rideInfo.dropoff.lng}&key=${GOOGLE_MAPS_API_KEY}`
            ),
          ]);

          const pickupAddress =
            pickupResponse.data.results[0]?.formatted_address ||
            'Pickup Address Not Found';
          const dropoffAddress =
            dropoffResponse.data.results[0]?.formatted_address ||
            'Dropoff Address Not Found';

          setRideInfo((prev) => ({
            ...prev,
            pickupAddress,
            dropoffAddress,
          }));
        } catch (error) {
          console.error('Error fetching addresses:', error);
        }
      }
    };

    fetchAddresses();
  }, [rideInfo]);

  // Initialize Directions Service
  useEffect(() => {
    if (rideInfo && isLoaded) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: rideInfo.pickup,
          destination: rideInfo.dropoff,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
            setEta(result.routes[0].legs[0].duration.text);
            setDistance(result.routes[0].legs[0].distance.text);
          } else {
            console.error('Error fetching directions:', result);
          }
        }
      );
    }
  }, [rideInfo, isLoaded]);

  // Use Geolocation API to track driver's actual location
  useEffect(() => {
    if (!isLoaded || !rideInfo || !directions) return;

    if (navigator.geolocation) {
      // Watch position and update driver location
      const id = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const currentLocation = { lat: latitude, lng: longitude };
          setDriverLocation(currentLocation);

          // Update current step index based on driver's location
          const steps = directions.routes[0].legs[0].steps;
          for (let i = 0; i < steps.length; i++) {
            const step = steps[i];
            const stepStart = {
              lat: step.start_location.lat(),
              lng: step.start_location.lng(),
            };
            const distanceToStepStart = getDistanceFromLatLonInKm(
              latitude,
              longitude,
              stepStart.lat,
              stepStart.lng
            );
            if (distanceToStepStart < 0.05) {
              setCurrentStepIndex(i);
              break;
            }
          }

          // Update map center to driver's location if not interacting
          if (mapInstance && !isMapInteracting) {
            mapInstance.panTo(currentLocation);
          }
        },
        (error) => {
          console.error('Error getting driver location:', error);
          alert(
            'Unable to access your location. Please enable location services.'
          );
        },
        { enableHighAccuracy: true, maximumAge: 0, timeout: 5000 }
      );
      watchIdRef.current = id;
    } else {
      alert('Geolocation is not supported by this browser.');
    }

    // Cleanup function to clear the watcher when component unmounts
    return () => {
      if (watchIdRef.current !== null) {
        navigator.geolocation.clearWatch(watchIdRef.current);
      }
    };
  }, [isLoaded, rideInfo, directions, mapInstance, isMapInteracting]);

  // Detect arrival at destination
  useEffect(() => {
    if (driverLocation && rideInfo) {
      const distanceToDestination = getDistanceFromLatLonInKm(
        driverLocation.lat,
        driverLocation.lng,
        rideInfo.dropoff.lat,
        rideInfo.dropoff.lng
      );

      // Assuming arrival if within 0.1 km (~100 meters)
      if (distanceToDestination <= 0.1) {
        if (watchIdRef.current !== null) {
          navigator.geolocation.clearWatch(watchIdRef.current);
        }
        navigate('/RideCompletionAndSummary', { state: { rideInfo } });
      }
    }
  }, [driverLocation, rideInfo, navigate]);

  // Helper function to calculate distance between two coordinates
  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (loading || !isLoaded) {
    return <div>Loading Ride Information...</div>;
  }

  return (
    <div className="driver-navigation-page">
      {/* Top Directions Panel */}
      {directions && (
        <div className="directions-panel">
          <h3>
            {directions.routes[0].legs[0].steps[currentStepIndex]
              ?.instructions.replace(/<[^>]*>?/gm, '') ||
              'Head to the starting point'}
          </h3>
        </div>
      )}

      {/* Google Map */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={16}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
        onLoad={(map) => {
          setMapInstance(map);
          // Center the map only once when it loads
          map.panTo(rideInfo.pickup);
        }}
        onDragStart={() => {
          setIsMapInteracting(true);
          if (interactionTimeoutRef.current) {
            clearTimeout(interactionTimeoutRef.current);
          }
        }}
        onDragEnd={() => {
          if (interactionTimeoutRef.current) {
            clearTimeout(interactionTimeoutRef.current);
          }
          interactionTimeoutRef.current = setTimeout(
            () => setIsMapInteracting(false),
            5000
          );
        }}
        onZoomChanged={() => {
          setIsMapInteracting(true);
          if (interactionTimeoutRef.current) {
            clearTimeout(interactionTimeoutRef.current);
          }
          interactionTimeoutRef.current = setTimeout(
            () => setIsMapInteracting(false),
            5000
          );
        }}
      >
        {/* Pickup Marker */}
        <Marker
          position={rideInfo.pickup}
          label="P"
          title="P:"
          icon={{
            url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
          }}
        />

        {/* Dropoff Marker */}
        <Marker
          position={rideInfo.dropoff}
          label="D"
          title="D:"
          icon={{
            url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
          }}
        />

        {/* Driver Marker */}
        {driverLocation && (
          <Marker
            position={driverLocation}
            label="You"
            title="Your Location"
            icon={{
              url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            }}
          />
        )}

        {/* Directions Renderer */}
        {directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              suppressMarkers: true,
              preserveViewport: true,
            }}
          />
        )}
      </GoogleMap>

      {/* Bottom Info Panel */}
      <div
        className={`bottom-panel ${showDetails ? 'expanded' : ''}`}
        onClick={() => setShowDetails(!showDetails)}
      >
        <div className="handle"></div>
        <div className="eta">{eta || 'Calculating ETA...'}</div>
        <div className="info-line">
          {distance} - {arrivalTime} - ${fare}
        </div>

        {showDetails && (
          <div className="details-content">
            <div className="detail-item">
              <strong>Your Earnings:</strong> ${driverCut}
            </div>
            <div className="detail-item">
              <strong>P:</strong> {rideInfo.pickupAddress || 'Loading...'}
            </div>
            <div className="detail-item">
              <strong>D:</strong> {rideInfo.dropoffAddress || 'Loading...'}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DriverNavigationPage;