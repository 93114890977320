import React, { useState, useRef, useEffect } from 'react'; 
import './DriverOnboarding.css';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, auth } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore'; // Firestore modular syntax

// Initialize Firebase Storage
const storage = getStorage(); 

const DriverOnboarding = () => {
  const [step, setStep] = useState(1);
  const [fullName, setFullName] = useState('');
  const [dob, setDob] = useState('');
  const [address, setAddress] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [vehicleMake, setVehicleMake] = useState('');
  const [vehicleModel, setVehicleModel] = useState('');
  const [vehicleYear, setVehicleYear] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  const [consent, setConsent] = useState(false);
  const [ssn, setSsn] = useState(''); // SSN field
  const [confirmSsn, setConfirmSsn] = useState(''); // SSN confirmation field
  const [licenseFront, setLicenseFront] = useState(null);
  const [licenseBack, setLicenseBack] = useState(null);
  const [registrationImage, setRegistrationImage] = useState(null);
  const [insuranceImage, setInsuranceImage] = useState(null);

  const fadeAnim = useRef(null);

  useEffect(() => {
    if (fadeAnim.current) {
      fadeAnim.current.style.opacity = 1;
    }
  }, [step]);

  // Prevent multiple submissions by checking if the driver profile already exists
  const checkDriverExists = async (uid) => {
    const driverDoc = await getDoc(doc(db, 'drivers', uid));
    return driverDoc.exists();
  };

  const handleNext = () => {
    if (step === 2 && ssn !== confirmSsn) {
      alert('SSN does not match. Please make sure both SSNs are the same.');
      return;
    }

    if (step === 5 && !consent) {
      alert('You must consent to the background check to proceed.');
      return;
    }

    setStep(step + 1);
    if (fadeAnim.current) {
      fadeAnim.current.style.opacity = 0;
    }
  };

  const handleBack = () => {
    setStep(step - 1);
    if (fadeAnim.current) {
      fadeAnim.current.style.opacity = 0;
    }
  };

  const uploadImage = async (file, imageName) => {
    if (!file) return null;

    const storageRef = ref(storage, `images/${imageName}`);
    try {
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      return url;
    } catch (error) {
      console.error('Error uploading image:', error);
      return null;
    }
  };

  const handleImageUploads = async () => {
    const licenseFrontUrl = await uploadImage(licenseFront, 'licenseFront.jpg');
    const licenseBackUrl = await uploadImage(licenseBack, 'licenseBack.jpg');
    const registrationImageUrl = await uploadImage(registrationImage, 'registrationImage.jpg');
    const insuranceImageUrl = await uploadImage(insuranceImage, 'insuranceImage.jpg');

    return { licenseFrontUrl, licenseBackUrl, registrationImageUrl, insuranceImageUrl };
  };

  const handleSubmit = async () => {
    const imageUrls = await handleImageUploads();

    const driverProfileData = {
      fullName,
      dob,
      address,
      licenseNumber,
      vehicleMake,
      vehicleModel,
      vehicleYear,
      licensePlate,
      consent,
      ssn, // Include SSN in submission
      licenseFront: imageUrls.licenseFrontUrl,
      licenseBack: imageUrls.licenseBackUrl,
      registrationImage: imageUrls.registrationImageUrl,
      insuranceImage: imageUrls.insuranceImageUrl,
    };

    try {
      const user = auth.currentUser;
      if (!user) {
        alert('User is not logged in');
        return;
      }

      // Check if the driver profile already exists
      const driverExists = await checkDriverExists(user.uid);
      if (driverExists) {
        alert('You have already submitted your driver profile.');
        return;
      }

      // Check if the user is already an admin to preserve the admin role
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const isAdmin = userDoc.exists() && userDoc.data().role === 'admin';

      // Save the driver profile to the 'drivers' collection, not 'users'
      await setDoc(doc(db, 'drivers', user.uid), {
        ...driverProfileData,
        role: isAdmin ? 'admin-driver' : 'driver', // Preserve admin role if applicable
        createdAt: new Date(),
      });

      alert('Your profile has been submitted for review.');
      setStep(1); // Reset the form
    } catch (error) {
      alert('There was an issue submitting your profile. Please try again.');
      console.error('Profile submission error:', error);
    }
  };

  const handleFileChange = (setImage) => (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  // SSN formatting function
  const formatSSN = (value) => {
    const ssnValue = value.replace(/\D/g, ''); // Remove non-digit characters
    if (ssnValue.length <= 3) return ssnValue;
    if (ssnValue.length <= 5) return `${ssnValue.slice(0, 3)}-${ssnValue.slice(3)}`;
    return `${ssnValue.slice(0, 3)}-${ssnValue.slice(3, 5)}-${ssnValue.slice(5, 9)}`;
  };

  const handleSsnChange = (e) => {
    setSsn(formatSSN(e.target.value));
  };

  const handleConfirmSsnChange = (e) => {
    setConfirmSsn(formatSSN(e.target.value));
  };

  return (
    <div className="onboarding-container">
      <div ref={fadeAnim} className="animated-screen">
        {step === 1 && (
          <div className="step-container">
            <h1>Welcome to Driver Onboarding</h1>
            <p>We are thrilled to have you join our driving community. Let’s get you set up quickly and easily.</p>
            <button className="submit-button" onClick={handleNext}>Start Your Journey</button>
          </div>
        )}

        {step === 2 && (
          <div className="step-container">
            <h2>Personal Information</h2>
            <input type="text" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
            <input type="date" placeholder="Date of Birth" value={dob} onChange={(e) => setDob(e.target.value)} />
            <input type="text" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
            <input type="text" placeholder="SSN (000-00-0000)" value={ssn} onChange={handleSsnChange} maxLength={11} />
            <input type="text" placeholder="Re-enter SSN" value={confirmSsn} onChange={handleConfirmSsnChange} maxLength={11} />
            <div className="button-container">
              <button onClick={handleBack}>Back</button>
              <button onClick={handleNext}>Next</button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="step-container">
            <h2>Driver's License Information</h2>
            <input type="text" placeholder="License Number" value={licenseNumber} onChange={(e) => setLicenseNumber(e.target.value)} />
            <label>Upload License Front</label>
            <input type="file" accept="image/*" onChange={handleFileChange(setLicenseFront)} />
            <label>Upload License Back</label>
            <input type="file" accept="image/*" onChange={handleFileChange(setLicenseBack)} />
            <div className="button-container">
              <button onClick={handleBack}>Back</button>
              <button onClick={handleNext}>Next</button>
            </div>
          </div>
        )}

        {step === 4 && (
          <div className="step-container">
            <h2>Vehicle Information</h2>
            <input type="text" placeholder="Vehicle Make" value={vehicleMake} onChange={(e) => setVehicleMake(e.target.value)} />
            <input type="text" placeholder="Vehicle Model" value={vehicleModel} onChange={(e) => setVehicleModel(e.target.value)} />
            <input type="text" placeholder="Vehicle Year" value={vehicleYear} onChange={(e) => setVehicleYear(e.target.value)} />
            <input type="text" placeholder="License Plate Number" value={licensePlate} onChange={(e) => setLicensePlate(e.target.value)} />
            <label>Upload Vehicle Registration</label>
            <input type="file" accept="image/*" onChange={handleFileChange(setRegistrationImage)} />
            <label>Upload Vehicle Insurance</label>
            <input type="file" accept="image/*" onChange={handleFileChange(setInsuranceImage)} />
            <div className="button-container">
              <button onClick={handleBack}>Back</button>
              <button onClick={handleNext}>Next</button>
            </div>
          </div>
        )}

        {step === 5 && (
          <div className="step-container">
            <h2>Background Check Consent</h2>
            <p>We require a background check to ensure the safety of our riders.</p>
            <div className="consent-button">
              <input type="checkbox" checked={consent} onChange={() => setConsent(!consent)} /> 
              <span className="consent-text">I consent to a background check.</span>
            </div>
            <div className="button-container">
              <button onClick={handleBack}>Back</button>
              <button onClick={handleNext}>Next</button>
            </div>
          </div>
        )}

        {step === 6 && (
          <div className="step-container">
            <h2>Review & Submit</h2>
            <p>Please review your information before submitting.</p>
            <div className="summary">
              <p><strong>Full Name:</strong> {fullName}</p>
              <p><strong>Date of Birth:</strong> {dob}</p>
              <p><strong>Address:</strong> {address}</p>
              <p><strong>SSN:</strong> {ssn}</p>
              <p><strong>License Number:</strong> {licenseNumber}</p>
              <p><strong>Vehicle Make:</strong> {vehicleMake}</p>
              <p><strong>Vehicle Model:</strong> {vehicleModel}</p>
              <p><strong>Vehicle Year:</strong> {vehicleYear}</p>
              <p><strong>License Plate:</strong> {licensePlate}</p>
            </div>
            <button className="submit-button" onClick={handleSubmit}>Submit</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DriverOnboarding;
