import React, { useState } from 'react';
import './LoginScreen.css';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase'; // Import Firestore database
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importing Font Awesome icons from react-icons

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!email || !password) {
      alert('Please enter both email and password.');
      return;
    }

    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Get the user data from Firestore
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        // Check if the user has the role of "admin"
        if (userData.role === 'admin') {
          navigate('/admin'); // Redirect admins to the admin dashboard
        } else {
          navigate('/driver-onboarding'); // Redirect non-admins to driver onboarding
        }
      } else {
        alert('No user data found.');
      }
    } catch (error) {
      alert(`Login Failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Toggle password visibility
  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className="login-container">
      <img src="/blitz_logo.png" alt="BLITZ Logo" className="logo" />
      <h1>Login</h1>

      <input 
        type="email" 
        placeholder="Email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        className="input-field"
      />

      <div className="password-container">
        <input 
          type={showPassword ? "text" : "password"} 
          placeholder="Password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          className="password-input"
        />
        <button 
          type="button" 
          className="toggle-password-button" 
          onClick={toggleShowPassword}
          aria-label={showPassword ? "Hide password" : "Show password"}
        >
          {showPassword ? <FaEyeSlash size={20} color="#8C1627" /> : <FaEye size={20} color="#8C1627" />}
        </button>
      </div>

      <button 
        onClick={handleLogin} 
        className={`login-button ${loading ? 'disabled' : ''}`} 
        disabled={loading}
      >
        {loading ? 'Logging in...' : 'Login'}
      </button>

      <p className="footer-text">
        Don't have an account?{' '}
        <span 
          className="register-link" 
          onClick={() => navigate('/register')}
          tabIndex="0" /* Make it focusable */
          onKeyPress={(e) => {
            if (e.key === 'Enter') navigate('/register');
          }}
        >
          Register
        </span>
      </p>
    </div>
  );
};

export default LoginScreen;
